import { useEffect, useState } from 'react'
import './ContactsCaption.scss'

interface ContactsCaptionProps {
  onContactUsClick: () => void
}

const ContactsCaption = ({ onContactUsClick }: ContactsCaptionProps) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 250
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  return (
    <>
      {isVisible && (
        <div className='contacts-caption'>
          <div className='contacts-caption__wrapper'>
            <div
              onClick={(e) => {
                e.preventDefault()
                window.open('tel:+447700158972', '_blank')
              }}
              className='contacts-caption__phone'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path d='M0 0h24v24H0z' fill='none'></path>
                <path d='M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z'></path>
              </svg>
            </div>
            <div className='contacts-caption__socials'>
              <span onClick={onContactUsClick} style={{ textDecoration: 'underline' }}>
                Contact Us
              </span>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault()
                  window.open('https://twitter.com/AdvanceVauxhall', '_blank')
                }}
                className='contacts-caption__socials-item'
              >
                <img src='img/social/twitter.png' alt='Twitter' />
              </a>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault()
                  window.open('https://wa.me/+447700158972', '_blank')
                }}
                className='contacts-caption__socials-item'
              >
                <img src='img/social/wa.png' alt='WhatsApp' />
              </a>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault()
                  window.open('https://m.me/154946447867848', '_blank')
                }}
                className='contacts-caption__socials-item'
              >
                <img src='img/social/fb-mess.png' width='23' height='23' alt='Facebook' />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ContactsCaption
