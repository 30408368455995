import { filter } from 'lodash'
import moment from 'moment'
import { Fragment, memo, useContext, useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Branch } from '../../models/branch/Branch'
import { Departaments } from '../../models/branch/Enum'
import SvgButton from '../common/buttons/SvgButton'
import { EnvironmentContext } from '../providers/EnvironmentProvider'
import styles from './Accordion.module.css'
import Scrollbars from 'react-custom-scrollbars-2'
import Loader from '../common/loader/ComponentLoader'

const Accordion = ({ handleShowModal, scrollable, onChatClick }: any) => {
  const { env } = useContext(EnvironmentContext)
  const [opened, setOpened] = useState<number>(1)

  const handleAccordionClick = (branchId: number) => {
    setOpened(branchId)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleMessengerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.open('https://m.me/154946447867848', '_blank')
  }

  const handleWhatsAppClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.open('https://wa.me/+447700158972', '_blank')
  }

  const handleEmailClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.open('mailto:info@email.advancevauxhall.co.uk', '_blank')
  }

  // const handleSmsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault()
  //   window.open('sms:+447700158972', '_blank')
  // }

  const handleChatClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onChatClick()
  }

  return (
    <>
      <div className='accordion accordion--top'>
        {env?.branches ? (
          <Scrollbars
            autoHeight
            autoHeightMin={scrollable ? '500px' : '700px'}
            // autoHeightMax='660px'
            style={{ width: '100%' }}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  width: '3px',
                  borderRadius: '4px',
                  backgroundColor: '#d4141c',
                }}
              />
            )}
          >
            <div className='accordion__title accordion__title--top'>
              <span className='accordion__title'>Contact Us</span>
              <span className='accordion__hint'>click to open</span>
            </div>

            <div className='js-accordion ui-accordion ui-widget ui-helper-reset'>
              {env?.branches?.map((e: Branch, i: number) => (
                <Fragment key={i}>
                  <div
                    className='accordion__zag'
                    onClick={() => {
                      handleAccordionClick(e.id)
                    }}
                  >
                    {e.branchName}
                  </div>

                  <Collapse isOpened={opened === e.id}>
                    <div className='accordion__body'>
                      <a href={`tel:${e.salesPhone}`} className='accordion__phone-link link--phone-icon'>
                        {e.salesPhone}
                      </a>
                      <p className='accordion__desc'>
                        {e.address} &nbsp;
                        <span
                          style={{ color: '#00e', cursor: 'pointer' }}
                          onClick={() => {
                            handleShowModal(e)
                          }}
                          className='onmap'
                        >
                          directions
                        </span>
                      </p>

                      <Tabs className='accordion__tabs js-tabs'>
                        <TabList className='accordion__tabs-header tabs__caption'>
                          {e.id != 3 && (
                            <Tab
                              className='accordion__tabs-title-react'
                              style={{ borderRadius: '0px', borderBottom: '0px' }}
                            >
                              Sales
                            </Tab>
                          )}
                          <Tab
                            className='accordion__tabs-title-react'
                            style={{ borderRadius: '0px', borderBottom: '0px' }}
                          >
                            Service
                          </Tab>
                          <Tab
                            className='accordion__tabs-title-react'
                            style={{ borderRadius: '0px', borderBottom: '0px' }}
                          >
                            Parts
                          </Tab>
                        </TabList>

                        {e.id != 3 && (
                          <TabPanel style={{ padding: '16px' }}>
                            <ul className='accordion__list'>
                              {filter(e.depSchedule, { depId: Departaments.Salse }).map((e: any, i: number) => (
                                <li key={i} className='accordion__list-item'>
                                  {moment.weekdays(e.dayOfWeek)}
                                  <span className='accordion__list-item--right'>
                                    {e.isOpen ? `${e.openingHours} - ${e.closureHours}` : 'Closed'}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </TabPanel>
                        )}

                        <TabPanel style={{ padding: '16px' }}>
                          <ul className='accordion__list'>
                            {filter(e.depSchedule, { depId: Departaments.Service }).map((e: any, i: number) => (
                              <li key={i} className='accordion__list-item'>
                                {moment.weekdays(e.dayOfWeek)}
                                <span className='accordion__list-item--right'>
                                  {e.isOpen ? `${e.openingHours} - ${e.closureHours}` : 'Closed'}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </TabPanel>

                        <TabPanel style={{ padding: '16px' }}>
                          <ul className='accordion__list'>
                            {filter(e.depSchedule, { depId: Departaments.Parts }).map((e: any, i: number) => (
                              <li key={i} className='accordion__list-item'>
                                {moment.weekdays(e.dayOfWeek)}
                                <span className='accordion__list-item--right'>
                                  {e.isOpen ? `${e.openingHours} - ${e.closureHours}` : 'Closed'}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </Collapse>
                </Fragment>
              ))}
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.buttonsRow}>
                <SvgButton text='Messenger' onClick={handleMessengerClick}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' fill='none'>
                    <path
                      d='M9.5 0C4.25329 0 0 3.93822 0 8.7963C0 11.5645 1.38145 14.0337 3.54037 15.6461V19L6.77503 17.2247C7.63829 17.4636 8.55283 17.5926 9.5 17.5926C14.7467 17.5926 19 13.6544 19 8.7963C19 3.93822 14.7467 0 9.5 0ZM10.4441 11.8457L8.02484 9.26543L3.30435 11.8457L8.49689 6.33333L10.9752 8.91358L15.6366 6.33333L10.4441 11.8457Z'
                      fill='#CE181F'
                    />
                  </svg>
                </SvgButton>
                <SvgButton text='WhatsApp' onClick={handleWhatsAppClick}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
                    <path
                      d='M15.3769 2.619C13.6854 0.927 11.4332 0 9.03618 0C4.09749 0 0.0723619 4.005 0.0723619 8.919C0.0723619 10.494 0.488442 12.024 1.26633 13.374L0 18L4.74874 16.758C6.0603 17.469 7.53467 17.847 9.03618 17.847C13.9749 17.847 18 13.842 18 8.928C18 6.543 17.0683 4.302 15.3769 2.619ZM9.03618 16.335C7.69749 16.335 6.38593 15.975 5.23719 15.3L4.96583 15.138L2.14372 15.876L2.89447 13.14L2.71357 12.861C1.97186 11.682 1.57387 10.314 1.57387 8.919C1.57387 4.833 4.9206 1.503 9.02713 1.503C11.0171 1.503 12.8894 2.277 14.2915 3.681C15.7025 5.085 16.4714 6.948 16.4714 8.928C16.4894 13.014 13.1427 16.335 9.03618 16.335ZM13.1246 10.791C12.8985 10.683 11.795 10.143 11.596 10.062C11.3879 9.99 11.2432 9.954 11.0894 10.17C10.9357 10.395 10.5106 10.899 10.3839 11.043C10.2573 11.196 10.1216 11.214 9.89548 11.097C9.66935 10.989 8.94573 10.746 8.09548 9.99C7.42613 9.396 6.98291 8.667 6.84724 8.442C6.7206 8.217 6.82914 8.1 6.94673 7.983C7.04623 7.884 7.17286 7.722 7.28141 7.596C7.38995 7.47 7.43518 7.371 7.50754 7.227C7.5799 7.074 7.54372 6.948 7.48945 6.84C7.43518 6.732 6.98291 5.634 6.80201 5.184C6.6211 4.752 6.43116 4.806 6.29548 4.797C6.1598 4.797 6.01507 4.797 5.86131 4.797C5.70754 4.797 5.47236 4.851 5.26432 5.076C5.06533 5.301 4.48643 5.841 4.48643 6.939C4.48643 8.037 5.29146 9.099 5.4 9.243C5.50854 9.396 6.98291 11.646 9.22613 12.609C9.7598 12.843 10.1759 12.978 10.5015 13.077C11.0352 13.248 11.5236 13.221 11.9126 13.167C12.3467 13.104 13.2422 12.627 13.4231 12.105C13.6131 11.583 13.6131 11.142 13.5497 11.043C13.4864 10.944 13.3508 10.899 13.1246 10.791Z'
                      fill='#CE181F'
                    />
                  </svg>
                </SvgButton>
              </div>
              <div className={styles.buttonsRow}>
                <SvgButton text='Send Email' onClick={handleEmailClick}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'>
                    <path
                      d='M14.4 0H1.6C0.72 0 0.00799999 0.675 0.00799999 1.5L0 10.5C0 11.325 0.72 12 1.6 12H14.4C15.28 12 16 11.325 16 10.5V1.5C16 0.675 15.28 0 14.4 0ZM14.4 10.5H1.6V3L8 6.75L14.4 3V10.5ZM8 5.25L1.6 1.5H14.4L8 5.25Z'
                      fill='#CE181F'
                    />
                  </svg>
                </SvgButton>
                <SvgButton text='Online-Chat' onClick={handleChatClick}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
                    <path
                      d='M15.2 3.2H13.6V10.4H3.2V12C3.2 12.44 3.56 12.8 4 12.8H12.8L16 16V4C16 3.56 15.64 3.2 15.2 3.2ZM12 8V0.8C12 0.36 11.64 0 11.2 0H0.8C0.36 0 0 0.36 0 0.8V12L3.2 8.8H11.2C11.64 8.8 12 8.44 12 8Z'
                      fill='#CE181F'
                    />
                  </svg>
                </SvgButton>
              </div>
              <div className={styles.buttonsRow}></div>
            </div>
          </Scrollbars>
        ) : (
          <div className={styles.loaderContainer}>
            <Loader width='50' height='50' />
          </div>
        )}
      </div>
    </>
  )
}

export default memo(Accordion)
