import { ReactNode } from 'react'

import { Popover, PopoverButton } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/16/solid'

import SubPopoverPanel from './SubPopoverPanel'

interface SideBarMenuItemProps {
  title: string
  children: ReactNode
  onClose?: () => void
}
export const SideBarMenuItem = ({ title, children, onClose }: SideBarMenuItemProps) => {
  return (
    <Popover>
      <PopoverButton className='block w-full py-2 font-semibold bg-white text-base'>
        <div className='flex text-gray-500 items-center'>
          {title} <ChevronRightIcon className='h-6 w-6' />
        </div>
      </PopoverButton>
      <SubPopoverPanel onClose={onClose}>{children}</SubPopoverPanel>
    </Popover>
  )
}
