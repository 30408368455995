/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentLogDto } from '../models/ContentLogDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContentLogService {

  /**
   * @param url 
   * @returns ContentLogDto Success
   * @throws ApiError
   */
  public static getContentLog(
url: string,
): CancelablePromise<ContentLogDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/content-log/{url}',
      path: {
        'url': url,
      },
    });
  }

}
