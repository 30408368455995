/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = DefaultEmployee (Роль по умолчанию, минимум возможностей)
 *
 * 1 = Administrator (Имеет доступ ко всем страницам и всему функционалу)
 *
 * 2 = Accountant (Бухгалтер)
 *
 * 3 = BdcAdvisor
 *
 * 4 = BodyshopManager
 *
 * 5 = PartsDriver
 *
 * 6 = PartsManager
 *
 * 7 = SalesAdministrator
 *
 * 8 = SalesConsultant (Анжела его еще называет Sales Exec)
 *
 * 9 = SalesManager
 *
 * 10 = ServiceAdvisor
 *
 * 11 = ServiceDriver
 *
 * 12 = ServiceManager
 *
 * 13 = SiteCoordinator
 *
 * 14 = Valetor
 *
 * 15 = Technician
 *
 * 16 = CarBuyer
 *
 * 17 = BodyshopOperative
 *
 * 18 = Marketing
 *
 * 19 = Maintenance
 */
export enum UserRole {
  /**
   * Роль по умолчанию, минимум возможностей
   */
  DefaultEmployee = 0,
  /**
   * Имеет доступ ко всем страницам и всему функционалу
   */
  Administrator = 1,
  /**
   * Бухгалтер
   */
  Accountant = 2,
  BdcAdvisor = 3,
  BodyshopManager = 4,
  PartsDriver = 5,
  PartsManager = 6,
  SalesAdministrator = 7,
  /**
   * Анжела его еще называет Sales Exec
   */
  SalesConsultant = 8,
  SalesManager = 9,
  ServiceAdvisor = 10,
  ServiceDriver = 11,
  ServiceManager = 12,
  SiteCoordinator = 13,
  Valetor = 14,
  Technician = 15,
  CarBuyer = 16,
  BodyshopOperative = 17,
  Marketing = 18,
  Maintenance = 19,
}
