/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartRequestAddressInputModel } from '../models/PartRequestAddressInputModel';
import type { PartRequestCustomerInfo } from '../models/PartRequestCustomerInfo';
import type { PartRequestModel } from '../models/PartRequestModel';
import type { WipInfoModel } from '../models/WipInfoModel';
import type { WipInfosInput } from '../models/WipInfosInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartRequestService {

  /**
   * @param id 
   * @returns WipInfoModel Success
   * @throws ApiError
   */
  public static getGetPartRequests(
id?: number,
): CancelablePromise<Array<WipInfoModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/GetPartRequests',
      query: {
        'id': id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @param partRequestId 
   * @param requestBody 
   * @returns boolean Created
   * @throws ApiError
   */
  public static postSavePartRequestWithNoPayment(
partRequestId?: number,
requestBody?: Array<WipInfosInput>,
): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/SavePartRequestWithNoPayment',
      query: {
        'partRequestId': partRequestId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static postSaveApplyPartRequestAddress(
requestBody?: PartRequestAddressInputModel,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/SaveApplyPartRequestAddress',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @param partRequestId 
   * @returns PartRequestCustomerInfo Success
   * @throws ApiError
   */
  public static getGetPartRequestCustomerInfo(
partRequestId?: number,
): CancelablePromise<PartRequestCustomerInfo> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/GetPartRequestCustomerInfo',
      query: {
        'partRequestId': partRequestId,
      },
    });
  }

  /**
   * @param partRequestId 
   * @returns PartRequestModel Success
   * @throws ApiError
   */
  public static getGetPartEnquiry(
partRequestId?: number,
): CancelablePromise<PartRequestModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/GetPartEnquiry',
      query: {
        'partRequestId': partRequestId,
      },
    });
  }

}
