import { FC } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import styles from './ComponentLoader.module.css'

const ComponentLoader: FC<{ style?: any; height?: string; width?: string }> = ({ style, height, width }) => {
  return (
    <>
      <div className={styles.componentLoader} style={style ? style : {}}>
        <ThreeCircles
          height={height ? height : '100'}
          width={width ? width : '100'}
          color='#ce181f'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          ariaLabel='three-circles-rotating'
          outerCircleColor=''
          innerCircleColor=''
          middleCircleColor=''
        />
      </div>
    </>
  )
}

export default ComponentLoader
