import { fetchConnectApi, postConnectApi } from './fetch'

const URL_INIT_CONVERSATION = '/api/Twilio/InitConversation'
const URL_TWILIO_MESSAGES = '/api/Messages/GetTwilioMessagesForCustomer'
const URL_SEND_MESSAGE = '/api/Twilio/CustomerSendTwilioMessage'

const fetchInitConversation = (userId: string, data: any) =>
  fetchConnectApi(`${URL_INIT_CONVERSATION}/${userId}`, 'PATCH', data)
const fetchTwilioMessages = (conversationSid: string, userId: string) =>
  fetchConnectApi(`${URL_TWILIO_MESSAGES}?converstationSid=${conversationSid}&customerUid=${userId}`, 'GET')
const fetchSendMessage = (data: FormData) => postConnectApi(`${URL_SEND_MESSAGE}`, 'POST', data)

export const chatApi = {
  fetchInitConversation,
  fetchTwilioMessages,
  fetchSendMessage,
}
