import { FC } from 'react'
import { TwilioMessage } from '../../../../models/contactUs/TwilioMessage'
import avatar from '../../../../assets/images/chat/user-avatar.svg'
import moment from 'moment'
import * as linkify from 'linkifyjs'
import parse from 'html-react-parser'
import styles from './styles.module.scss'

interface IProps {
  message: TwilioMessage
}

const InBoundMessage: FC<IProps> = ({ message }) => {
  const renderMessage = (text: string) => {
    let fnd = linkify.find(text)
    if (fnd && fnd.length) {
      const newText = text
        .replace('link:', '')
        .replace(fnd[0].href, `<a className='${styles.chatLink}' target='_blank' href='${fnd[0].href}'>link</a>`)
      return <>{parse(newText)}</>
    } else {
      return <>{parse(text)}</>
    }
  }

  return (
    <div className='chat__message chat__message--user'>
      <div className='chat__avatar'>
        <img src={avatar} alt='avatar' />
      </div>
      <div className='chat__message-wrapper'>
        <div className='chat__message-bubble'>{renderMessage(message.message)}</div>
        <div className='chat__message-info'>
          <span className='chat__author'>{message.sender}</span>
          <time className='chat__date'>{moment(message.dateSent).format('hh:mm')}</time>
        </div>
      </div>
    </div>
  )
}

export default InBoundMessage
