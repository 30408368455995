/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MakeEnquiryInput } from '../models/MakeEnquiryInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnquiryService {

  /**
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static postEnquiry(
requestBody?: MakeEnquiryInput,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/Enquiry',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

}
