/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Desktop
 *
 * 1 = Mobile
 */
export enum ContentLogImageType {
  Desktop = 0,
  Mobile = 1,
}
