/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Draft
 *
 * 1 = Published
 */
export enum ArticlePublishStatus {
  Draft = 0,
  Published = 1,
}
