import { PopoverGroup } from '@headlessui/react'
import { PhoneIcon } from '@heroicons/react/16/solid'
import { Link } from '../../components/common/Link'
import './Override.css'
import { menuSections } from './menuConfig'
import { MenuItem } from './components/MenuItem'
import { MenuItemSection } from './components/MenuItemSection'
import { SideBarMenu } from './components/SidebarMenu'

const Menu = ({ onContactUsClick }: any) => {
  return (
    <header
      className='flex items-center w-full ms-auto bg-white shadow sticky top-0 min-h-20 lg:px-6 px-3'
      style={{ boxSizing: 'border-box', zIndex: 10000 }}
    >
      <div className='flex items-center w-full'>
        <a className='header__logo flex-shrink-0' href='/'>
          <img src='/img/logo.svg' alt='Advance Motors' aria-label='Advance Motors' />
        </a>
        <div className='flex-grow overflow-hidden'>
          <div className='hidden lg:flex w-full'>
            <div className='lg:ml-6 overflow-x-auto scrollbar'>
              <PopoverGroup className='flex'>
                {menuSections.map((item) =>
                  item.isLink ? (
                    <div className='px-4 py-6' key={item.href}>
                      <Link
                        className='text-gray-500 group bg-white inline-flex text-base font-medium hover:text-gray-900 text-medium'
                        to={item.href ?? ''}
                        isExternal={item.isNextLink}
                      >
                        <span className='truncate'>{item.text}</span>
                      </Link>
                    </div>
                  ) : (
                    <MenuItem optionLabel={item.title} key={item.title}>
                      {item.sections.map(({ title, ...rest }) => (
                        <MenuItemSection key={title} title={title} {...rest} />
                      ))}
                    </MenuItem>
                  ),
                )}
              </PopoverGroup>
            </div>
          </div>
        </div>
        <div className='hidden lg:flex items-center text-base pl-2 gap-2 min-w-[177px]'>
          <div className='text-gray-500 text-center underline cursor-pointer flex items-center gap-2'>
            <PhoneIcon className='h-6 w-6 shrink-0' />
            <span className='truncate' onClick={onContactUsClick}>
              Contact Us
            </span>
          </div>
          <img src='/img/logo-2.svg' alt='Advance Vauxhall' style={{ height: 'auto', width: 70 }} />
        </div>
        <SideBarMenu />
      </div>
    </header>
  )
}

export default Menu
