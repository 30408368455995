/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StripePaymentInput } from '../models/StripePaymentInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StripeService {

  /**
   * @param requestBody 
   * @returns string Created
   * @throws ApiError
   */
  public static postStripeCreatePayment(
requestBody?: StripePaymentInput,
): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/Stripe/CreatePayment',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }

}
