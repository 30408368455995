import { Link } from '../../../components/common/Link'
import { AppLink } from '../menuConfig'

interface SideBarSubLinkProps extends AppLink {
  text: string
  link: string
  handleClick?: () => void
}

const SideBarSubLink = ({ text, link, handleClick, isNextLink }: SideBarSubLinkProps) => {
  return (
    <Link className='font-normal text-gray-500 text-base' to={link} onClick={handleClick} isExternal={isNextLink}>
      {text}
    </Link>
  )
}

export default SideBarSubLink
