import SVG from 'react-inlinesvg'

import SideBarSubLink from './SideBarSubLink'
import { AppLink, SectionItem } from '../menuConfig'
import { Link } from '../../../components/common/Link'

interface SideBarMenuSectionProps extends AppLink {
  onItemClick?: () => void
  title: string
  items: SectionItem[]
  titleLink?: string
  icon?: { className: string; path: string }
}

export const SideBarMenuSection = ({
  onItemClick,
  title,
  items,
  titleLink,
  isNextLink,
  icon,
}: SideBarMenuSectionProps) => {
  return (
    <div className='p-6'>
      <div className='-my-2 space-y-2'>
        {titleLink ? (
          <Link
            className='w-full py-2 font-semibold text-gray-700 text-base flex items-center gap-2'
            to={titleLink}
            onClick={onItemClick}
            isExternal={isNextLink}
          >
            {icon && <SVG src={icon.path} className={icon.className} />}
            {title}
          </Link>
        ) : (
          <div className='w-full py-2 font-semibold text-base flex items-center gap-2'>
            {icon && <SVG src={icon.path} className={icon.className} />}
            {title}
          </div>
        )}
      </div>
      <hr className='border-red-700' style={{ margin: 0 }} />
      {items.map(({ title, link, isNextLink, icon }) => (
        <div key={title} className='flex items-center gap-2 pt-2'>
          {icon && <SVG src={icon.path} className={icon.className} />}
          <SideBarSubLink text={title} link={link ?? '#'} handleClick={onItemClick} isNextLink={isNextLink} />
        </div>
      ))}
    </div>
  )
}
