/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = Reservation
 *
 * 2 = OnlinePayment
 *
 * 3 = DealOffer
 *
 * 4 = PartRequest
 *
 * 5 = NewReservation
 *
 * 6 = DealSetup
 */
export enum PaymentType {
  Reservation = 1,
  OnlinePayment = 2,
  DealOffer = 3,
  PartRequest = 4,
  NewReservation = 5,
  DealSetup = 6,
}
