import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface LinkProps {
  to: string
  isExternal?: boolean
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
  target?: '_self' | '_blank' | '_parent' | '_top'
  rel?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const Link = ({ to, isExternal = false, className, children, target = '_self', rel, onClick }: LinkProps) => {
  if (isExternal) {
    return (
      <a
        href={to}
        className={className}
        target={target}
        rel={rel || (target === '_blank' ? 'noopener noreferrer' : undefined)}
        onClick={onClick}
      >
        {children}
      </a>
    )
  }

  return (
    <RouterLink to={to} className={className} onClick={onClick}>
      {children}
    </RouterLink>
  )
}
