/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Undefined
 *
 * 1 = New
 *
 * 2 = Pending
 *
 * 3 = Confirmed
 */
export enum PartRequestEnum {
  Undefined = 0,
  New = 1,
  Pending = 2,
  Confirmed = 3,
}
