import { Fragment, ReactNode } from 'react'

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'

interface MenuItemProps {
  optionLabel: string
  children?: ReactNode
}

export const MenuItem = ({ optionLabel, children }: MenuItemProps) => {
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <div className='flex relative self-center'>
            <div className='px-4 py-6 sm:px-6 lg:px-4 max-w-[200px] w-full'>
              <PopoverButton
                className={`
              ${open ? 'text-gray-900' : 'text-gray-500'}
              group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:text-red-700
            `}
                style={{ lineHeight: '24px' }}
              >
                <span className='truncate'>{optionLabel}</span>
                <svg
                  className='text-gray-400 ml-1 h-5 w-5 group-hover:text-gray-500 flex-shrink-0'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                  aria-hidden='true'
                >
                  <path
                    fillRule='evenodd'
                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>
              </PopoverButton>
            </div>
          </div>
          <Transition
            as={Fragment}
            enter='transition-enter transition-enter-from'
            enterTo='transition-enter transition-enter-to'
            leave='transition-leave transition-leave-from'
            leaveTo='transition-leave transition-leave-to'
          >
            <PopoverPanel
              className='absolute z-10 inset-x-0 transform shadow-lg top-20 bg-gray-100 transition ease-out duration-200'
              onMouseLeave={close}
            >
              <div
                className='p-6 xl:mx-56 lg:mx-24 flex flex-col flex-wrap gap-8 max-h-400 w-fit'
                style={{ boxSizing: 'border-box' }}
              >
                {children}
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
