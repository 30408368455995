import { actions } from './store'

export const URL_API = process.env.REACT_APP_API_BASE_URL || 'http://internalapitest.advance-motors.co.uk/'

// 10= min by default
const CACHE_LIFETIME_MLSEC = 600000

const redux: any = actions

const cache: any = {}

const getFetchCache = (url: string) => {
  //return null;

  const itemStr = localStorage.getItem(url)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(url)
    return null
  }
  return item.value

  //return cache[url];
}

const setFetchCache = (url: string, data: any) => {
  try {
    //cache[url] = data;
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: data,
      expiry: now.getTime() + CACHE_LIFETIME_MLSEC,
    }
    localStorage.setItem(url, JSON.stringify(item))
  } catch {}
}

export const loadData = async (url: string, setData: any, useCache: boolean = true) => {
  let data = await fetchData(url, 'GET', null, useCache)
  if (data) setData(data)
  else setData(null)
}

export const fetchData: any = async (url: string, method = 'GET', data: any = null, useCache: boolean = true) => {
  // запрос с кеша
  let retData = useCache ? getFetchCache(url) : undefined

  if (retData) return retData

  redux.setLoading({ loading: true })
  let response: any = null
  try {
    response = await fetch(URL_API + url, {
      headers: {
        // 'Content-Type': method=="POST" ? "multipart/form-data" : "application/json",
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        accept: 'text/plain',
      },
      method,
      body: data ? JSON.stringify(data) : null,
    })

    if (response.status != 204) {
      let text = await response.text()
      if (text) retData = JSON.parse(text)

      if (!response.ok) {
        const message = `An error has occured: ${response.status} - ${response.statusText} - ${JSON.stringify(retData)}`
        throw new Error(message)
      }

      // сохраняем кеш
      if (method == 'GET') setFetchCache(url, retData)
    }
  } catch (error) {
    // api поменялось и ломаеются старе запросы
    localStorage.removeItem(url)

    // возможно отсутствие данных
    //alert("DEBUG: " + URL_API + url + " - " + error);

    console.log(response)
    console.log(error)
    retData = null
  }

  redux.setLoading({ loading: false })
  return retData
}

export async function postData(url: string, data: any) {
  var fileData: any = document.querySelectorAll('input[type="file"]')

  var formData = new FormData()

  if (fileData) {
    fileData.forEach((e: any) => {
      formData.append('File' + e.id, e.files[0])
    })
  }

  Object.keys(data).forEach((key: string) => {
    let arr = (data[key] + '')?.split(',') || []
    // если это массив чере заятую, то разбиваем на параметры
    if (arr.length > 1) {
      arr.forEach((v: string) => {
        formData.append(key, v)
      })
    } else formData.append(key, data[key])
  })

  const response: any = fetch(URL_API + url, {
    method: 'POST',

    body: formData,
  })

  let ret = await response

  // let ret = await response.text();

  if (ret && (ret.status == 200 || ret.status == 201)) {
    return ret.statusText
  } else {
    return null
  }
}

export async function postFormData(url: string, formData: FormData) {
  const response: any = fetch(URL_API + url, {
    method: 'POST',
    body: formData,
  })

  let ret = await response

  if (ret && (ret.status == 200 || ret.status == 201)) {
    return ret.statusText
  } else {
    return null
  }
}

export async function postValueCaraData(url: string, data: any) {
  var fileData: any = document.querySelectorAll('input[type="file"]')

  var formData = new FormData()

  if (fileData) {
    fileData.forEach((e: any) => {
      formData.append('File' + e.id, e.files[0])
    })
  }

  Object.keys(data).forEach((key: string) => {
    let arr = (data[key] + '')?.split(',') || []
    // если это массив чере заятую, то разбиваем на параметры
    if (arr.length > 1) {
      arr.forEach((v: string) => {
        formData.append(key, v)
      })
    } else formData.append(key, data[key])
  })

  const response: any = fetch(URL_API + url, {
    method: 'POST',

    body: formData,
  })

  let ret = await response

  // let ret = await response.text();

  if (ret && (ret.status == 200 || ret.status == 201)) {
    return ret.json()
  } else {
    return null
  }
}
