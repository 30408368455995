/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Left
 *
 * 1 = Right
 */
export enum ContentLogImagePosition {
  Left = 0,
  Right = 1,
}
