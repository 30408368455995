import { FC } from 'react'
import { TwilioMessage } from '../../../../models/contactUs/TwilioMessage'
import avatar from '../../../../assets/images/chat/user-avatar.svg'
import moment from 'moment'

interface IProps {
  message: TwilioMessage
}

const InBoundMessage: FC<IProps> = ({ message }) => {
  return (
    <div className='chat__message chat__message--user'>
      <div className='chat__avatar'>
        <img src={avatar} alt='avatar' />
      </div>
      <div className='chat__message-wrapper'>
        <div className='chat__message-bubble'>{message.message}</div>
        <div className='chat__message-info'>
          <span className='chat__author'>{message.sender}</span>
          <time className='chat__date'>{moment(message.dateSent).format('hh:mm')}</time>
        </div>
      </div>
    </div>
  )
}

export default InBoundMessage
