/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadSourceDto } from '../models/LeadSourceDto';
import type { LeadSourceDtoInput } from '../models/LeadSourceDtoInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LeadService {

  /**
   * @param id 
   * @param priority 
   * @returns any Success
   * @throws ApiError
   */
  public static postLeadsNotificationsNew(
id: number,
priority: number = 1,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/leads/{id}/notifications/new',
      path: {
        'id': id,
      },
      query: {
        'priority': priority,
      },
    });
  }

  /**
   * @param requestBody 
   * @returns number Created
   * @throws ApiError
   */
  public static postLeadSources(
requestBody?: LeadSourceDtoInput,
): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/Lead/sources',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }

  /**
   * @param leadId 
   * @returns LeadSourceDto Success
   * @throws ApiError
   */
  public static getLeadSources(
leadId?: number,
): CancelablePromise<Array<LeadSourceDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/Lead/sources',
      query: {
        'leadId': leadId,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }

}
