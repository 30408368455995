/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = HP
 *
 * 2 = PCP
 *
 * 3 = CS0
 *
 * 5 = x4x4
 *
 * 6 = PCPZero
 *
 * 7 = FL
 *
 * 9 = BCH
 */
export enum FinanceTypeEnum {
  HP = 1,
  PCP = 2,
  CS0 = 3,
  x4x4 = 5,
  PCPZero = 6,
  FL = 7,
  BCH = 9,
}
