import { createSlice } from '@reduxjs/toolkit'

const initialModel: any = {}

const applyFinanceSlice = createSlice({
  name: 'applyFinance',
  initialState: initialModel,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
  },
})

export default applyFinanceSlice
export const { reducer: applyFinanceReducer, actions: applyFinanceActions } = applyFinanceSlice
