/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonService {

  /**
   * Lookup addresses by postcode or part postcode
   * @param postcode postcode or part postcode
   * @returns Address Success
   * @throws ApiError
   */
  public static getLookupAddress(
postcode: string,
): CancelablePromise<Array<Address>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/LookupAddress',
      query: {
        'postcode': postcode,
      },
      errors: {
        500: `Server Error`,
      },
    });
  }

}
