import { createSlice } from '@reduxjs/toolkit'

const initialModel: any = {}

const buildDealSlice = createSlice({
  name: 'buildDeal',
  initialState: initialModel,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
  },
})

export default buildDealSlice
export const { reducer: abuildDealReducer, actions: buildDealActions } = buildDealSlice
