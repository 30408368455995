import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContactUsReducerModel } from '../../models/contactUs/ContactUsReducerModel'
import { MessagesGroup, TwilioMessage } from '../../models/contactUs/TwilioMessage'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

const initialContactUsState: ContactUsReducerModel = {
  conversationId: undefined,
  userId: undefined,
  isActionFormExpanded: false,
  isChatExpanded: false,
  isLoading: false,
  error: false,
  messages: undefined,
  startedDate: undefined,
  startedTime: undefined,
  showChat: true,
  conversationClosed: false,
  // virtual assistant
  vaConversationId: undefined,
  isVaChatExpanded: false,
  vaMessages: undefined,
  vaStartedDate: undefined,
  vaStartedTime: undefined,
  showVirtualAssistant: false,
  vaConversationClosed: false,
  showTyping: false,
}

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: initialContactUsState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    setExpanded(state, action: PayloadAction<boolean>) {
      state.isActionFormExpanded = action.payload
      if (action.payload) {
        state.isVaChatExpanded = false
      }
    },
    setConversationId(state, action: PayloadAction<string>) {
      state.conversationId = action.payload
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload
    },
    setMessages(state, action: PayloadAction<MessagesGroup[]>) {
      state.messages = action.payload
    },
    setStartedDate(state, action: PayloadAction<string>) {
      state.startedDate = action.payload
    },
    setStartedTime(state, action: PayloadAction<string>) {
      state.startedTime = action.payload
    },
    setChatExpanded(state, action: PayloadAction<boolean>) {
      state.isChatExpanded = action.payload
      if (action.payload) {
        state.isActionFormExpanded = false
        state.isVaChatExpanded = false
      }
    },
    updateMessages(state, action: PayloadAction<MessagesGroup[]>) {
      state.messages = action.payload
    },
    addMessage(state, action: PayloadAction<TwilioMessage>) {
      const current = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      //if (!state.isChatExpanded) state.isChatExpanded = true
      if (state.messages && state.messages.length > 0) {
        const fnd = state.messages.find((x) => x.date === current)
        let newArr = cloneDeep(state.messages)
        if (fnd) {
          newArr.forEach((elem) => {
            if (elem.date === current) {
              if (action.payload.type !== 1 && elem.messages.find((e) => e.id === action.payload.id)) {
              } else elem.messages.push(action.payload)
            }
          })
        } else {
          let newGroup: MessagesGroup = {
            date: current,
            messages: [action.payload],
          }
          newArr.push(newGroup)
        }

        state.messages = newArr
      } else {
        const newGroup: MessagesGroup = {
          date: current,
          messages: [action.payload],
        }

        state.messages = [newGroup]
      }
    },
    setShowChat(state, action: PayloadAction<boolean>) {
      state.showChat = action.payload
    },
    setConversationClosed(state, action: PayloadAction<boolean>) {
      state.conversationClosed = action.payload
    },

    // virtual assistant
    setVaConversationId(state, action: PayloadAction<string | undefined>) {
      state.vaConversationId = action.payload
    },
    clearVaConversationMessages(state) {
      state.vaMessages = undefined
    },
    setVaChatExpanded(state, action: PayloadAction<boolean>) {
      state.isVaChatExpanded = action.payload
      if (action.payload) {
        state.isActionFormExpanded = false
        state.isChatExpanded = false
      }
    },
    setVaStartedDate(state, action: PayloadAction<string>) {
      state.vaStartedDate = action.payload
    },
    setVaStartedTime(state, action: PayloadAction<string>) {
      state.vaStartedTime = action.payload
    },
    addVaMessage(state, action: PayloadAction<TwilioMessage>) {
      const current = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      //if (!state.isVaChatExpanded) state.isVaChatExpanded = true
      if (state.vaMessages && state.vaMessages.length > 0) {
        const fnd = state.vaMessages.find((x) => x.date === current)
        let newArr = cloneDeep(state.vaMessages)
        if (fnd) {
          newArr.forEach((elem) => {
            if (elem.date === current) {
              if (action.payload.type !== 1 && elem.messages.find((e) => e.id === action.payload.id)) {
              } else elem.messages.push(action.payload)
            }
          })
        } else {
          let newGroup: MessagesGroup = {
            date: current,
            messages: [action.payload],
          }
          newArr.push(newGroup)
        }

        state.vaMessages = newArr
      } else {
        const newGroup: MessagesGroup = {
          date: current,
          messages: [action.payload],
        }

        state.vaMessages = [newGroup]
      }
      if (action.payload.type !== 1) state.showTyping = false
      else state.showTyping = true
    },
    setVaMessages(state, action: PayloadAction<MessagesGroup[]>) {
      state.vaMessages = action.payload
    },
    setShowVirtualAssistant(state, action: PayloadAction<boolean>) {
      state.showVirtualAssistant = action.payload
      if (!action.payload) state.isVaChatExpanded = false
    },
    setVaConversationClosed(state, action: PayloadAction<boolean>) {
      state.vaConversationClosed = action.payload
    },
    setShowTyping(state, action: PayloadAction<boolean>) {
      state.showTyping = action.payload
    },
  },
})

export default contactUsSlice
export const { reducer: contactUsReducer, actions: contactUsActions } = contactUsSlice
