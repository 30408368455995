import React, { useState, useEffect, useRef } from 'react'
import Helmet from './Helmet'
import * as utils from '../../utils'

const LOAD_URL = 'WebSiteUrl/GetUrlDataByUrl'

const App = ({ img = null, descr = '', price = '', trimstyle = '', metaTitle = '', id = '' }) => {
  const [data, setData] = useState(null)
  const refImg = useRef(null)
  // удаляем последний символ /

  useEffect(() => {
    // так получаем url без ?параметров=123
    let url = window.location.origin + window.location.pathname

    //убираем последний "/"
    url = url.replace(/\/+$/, '')

    //  url = "https://advancevauxhall.co.uk/new-cars-offers/astra/catalog/:id";
    //url = "https://advancevauxhall.co.uk/stock-car"
    //url="https://advancevauxhall.co.uk/new-cars-offers/astra/catalog"

    // это описание авто, запрашиваем шаблон мета текста как /:id 
    if (img) {
      url = url.substr(0, url.lastIndexOf('/')) + '/:id'
    }

    utils.api.loadData(`${LOAD_URL}?url=${url}`, setData, false)
  }, [window.location.href])

  // let urlCanonical = window.location.href;
  // if (!data?.descriptionTagText) {
  // urlCanonical = window.location.origin + window.location.pathname;
  // }

  const description =
    replaceByLable(data?.descriptionTagText, descr, price, trimstyle, id) ||
    `Find your ideal new or used vehicle at Advance Vauxhall. Main Vauxhall dealer in Berkshire, Buckinghamshire & Hertfordshire. Book a service or request parts`

  const title =
    replaceByLable(data?.titleTagText, metaTitle ? metaTitle : descr, price, trimstyle, id) || 'Advance Vauxhall'

  return <Helmet title={title} description={description} img={img} />
}

const replaceByLable = (text, descr, price, trimstyle, id) => {
  if (!text) return text

  return text
    .replaceAll('#new car trim#', descr)
    .replaceAll('#car-description#', descr)
    .replaceAll('#car description#', descr)
    .replaceAll('#price#', price)
    .replaceAll('#trimstyle#', trimstyle)
    .replaceAll('#Id#', id)
}

export default App
