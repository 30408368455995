import vauxhallLogo from '../../assets/images/logos/vauxhall_logo.svg'
import leapmotorLogo from '../../assets/images/logos/leapmotor_logo.svg'
import peugeotLogo from '../../assets/images/logos/peugeot_logo.svg'
import citroenLogo from '../../assets/images/logos/citroen_logo_sign.svg'

export interface AppLink {
  isNextLink?: boolean
}
export interface SectionItem extends AppLink {
  title: string
  link?: string
  icon?: { className: string; path: string }
}

interface MenuSection extends AppLink {
  title: string
  titleLink?: string
  items: SectionItem[]
  icon?: { className: string; path: string }
  order?: number
}

type MenuItem =
  | ({ isLink: true; text: string; href: string } & AppLink)
  | ({ isLink?: false; title: string; sections: MenuSection[] } & AppLink)

const vauxhallIcon = {
  className: 'h-6 w-6',
  path: vauxhallLogo,
}

const leapmotorIcon = {
  className: 'h-6 w-6',
  path: leapmotorLogo,
}

export const menuSections: Array<MenuItem> = [
  {
    title: 'Brands',
    sections: [
      {
        title: 'Brands',
        items: [
          {
            title: 'Vauxhall',
            link: '/vauxhall',
            isNextLink: true,
            icon: vauxhallIcon,
          },
          {
            title: 'Leapmotor',
            link: '/leapmotor',
            isNextLink: true,
            icon: {
              className: 'h-6 w-6',
              path: leapmotorLogo,
            },
          },
          {
            title: 'Peugeot',
            link: '/peugeot',
            isNextLink: true,
            icon: {
              className: 'h-6 w-6',
              path: peugeotLogo,
            },
          },
          {
            title: 'Citroen',
            link: '/citroen',
            isNextLink: true,
            icon: {
              className: 'h-6 w-6',
              path: citroenLogo,
            },
          },
        ],
      },
    ],
  },
  {
    title: 'New Cars',
    sections: [
      {
        title: 'Vauxhall New Cars Offers',
        titleLink: '/new-cars-offers',
        icon: vauxhallIcon,
        isNextLink: true,
        items: [
          {
            title: 'Astra ',
            link: '/new-cars-offers/astra',
            isNextLink: true,
          },
          {
            title: 'Astra Sports Tourer',
            link: '/new-cars-offers/astra-sports-tourer',
            isNextLink: true,
          },
          {
            title: 'Corsa',
            link: '/new-cars-offers/new-corsa',
            isNextLink: true,
          },
          {
            title: 'Combo Life',
            link: '/new-cars-offers/combo-e-life',
            isNextLink: true,
          },
          {
            title: 'Grandland',
            link: '/new-cars-offers/grandland',
            isNextLink: true,
          },
          {
            title: 'Mokka',
            link: '/new-cars-offers/mokka',
            isNextLink: true,
          },
          {
            title: 'Vivaro Life',
            link: '/new-cars-offers/vivaro-life-electric',
            isNextLink: true,
          },
          {
            title: 'New Frontera',
            link: '/new-cars-offers/new-frontera',
            isNextLink: true,
          },
        ],
      },
      {
        title: 'Vauxhall New Arrivals',
        titleLink: '/new-cars-offers',
        icon: vauxhallIcon,
        items: [{ title: 'New Grandland', link: '/new-grandland' }],
      },
      {
        title: 'Vauxhall Coming Soon',
        icon: vauxhallIcon,
        items: [{ title: 'New Frontera', link: '/new-frontera', isNextLink: true }],
      },
      {
        title: 'Leapmotor New Cars Offers',
        titleLink: '/leapmotor/new-cars-offers',
        isNextLink: true,
        items: [
          {
            title: 'C10',
            link: '/leapmotor/new-cars-offers/C10-5Dr-160kW-699kWh-AU-28552',
            isNextLink: true,
          },
          {
            title: 'T03',
            link: '/leapmotor/new-cars-offers/T03-5Dr-HAT-70kW-AU-28553',
            isNextLink: true,
          },
        ],
        icon: leapmotorIcon,
      },
      {
        title: 'New Cars Stock locator',
        titleLink: '/new-vehicles-stock',
        isNextLink: true,
        items: [],
      },
      {
        title: 'Motability Cars',
        titleLink: '/new-car-motability',
        isNextLink: true,
        items: [],
      },
      {
        title: 'Order Your New Vauxhall',
        titleLink: '/new-car-order',
        items: [],
      },
    ],
  },
  {
    title: 'New Vans',
    sections: [
      {
        title: 'New Vans offers',
        titleLink: '/new-vans-offers',
        isNextLink: true,
        items: [
          {
            title: 'Combo ',
            link: '/new-vans-offers/combo-cargo',
          },
          {
            title: 'Combo Electric',
            link: '/new-vans-offers/e-combo-cargo',
          },
          {
            title: 'Vivaro',
            link: '/new-vans-offers/vivaro',
          },
          {
            title: 'Vivaro Electric',
            link: '/new-vans-offers/vivaro-electric',
          },
          {
            title: 'New Movano',
            link: '/new-vans-offers/movano',
          },
          {
            title: 'New Movano Electric',
            link: '/new-vans-offers/movano-electric',
          },
        ],
      },
      {
        title: 'New Vans Stock locator',
        titleLink: '/new-vehicles-stock',
        isNextLink: true,
        items: [],
      },
    ],
  },
  {
    text: 'Used Vehicles',
    isLink: true,
    isNextLink: true,
    href: '/stock-cars',
  },
  {
    title: 'Electric',
    sections: [
      {
        title: 'New Electric Cars',
        titleLink: '/electric-cars',
        isNextLink: true,
        items: [{ title: 'Electric Cars Specials', link: '/electric-car-offer' }],
      },
      {
        title: 'More about electric vehicles',
        titleLink: '/electric-cars',
        isNextLink: true,
        items: [
          { title: 'Electric Vehicle Charging', link: '/electric-charging' },
          { title: 'Plug & Go', link: '/plug-and-go' },
          {
            title: 'Electric Vehicle Servicing',
            link: '/electric-servicing',
          },
          {
            title: 'The Top 5 Factors that Affect Mileage',
            link: '/electric-vehicle-range',
          },
        ],
      },
      // {
      //   title: 'Partnership',
      //   titleLink: '/partnership/grandlandlaunch',
      //   items: [
      //     {
      //       title: 'All-New Grandland Launch Offer',
      //       link: '/partnership/grandlandlaunch',
      //     },
      //   ],
      // },
    ],
  },
  {
    text: 'Value Your Car',
    isLink: true,
    href: '/value-car',
  },
  {
    title: 'Servicing & Parts',
    sections: [
      {
        title: 'Online Service & MOT',
        titleLink: '/service-booking',
        items: [
          {
            title: 'Online Service & MOT booking',
            link: '/service-booking',
          },
          {
            title: 'Service Plans',
            link: '/service-plan',
          },
        ],
      },
      {
        title: 'Part request',
        titleLink: '/part-request',
        items: [
          {
            title: 'Part request',
            link: '/part-request',
          },
        ],
      },
      {
        title: 'Service Offer',
        titleLink: '/service-booking',
        items: [
          {
            title: 'Service Special Offer',
            link: '/service-special-offer',
          },
          {
            title: 'Vehicle Health Check',
            link: '/vehicle-health-check',
          },
          {
            title: 'Free RAC',
            link: '/rac-breakdown-cover',
          },
          {
            title: 'Drive now, Pay later',
            link: '/drive-now-pay-later',
          },
        ],
      },
    ],
  },
  {
    title: 'Motability',
    sections: [
      {
        title: 'Motability',
        titleLink: '/motability-car-scheme',
        items: [
          { title: 'Motability car scheme', link: '/motability-car-scheme' },
          { title: 'Motability car search', link: '/new-car-motability', isNextLink: true },
          {
            title: 'Explore electric cars options',
            link: '/electric-car-motability',
          },
          { title: 'Motability Adaptations', link: '/motability-adaptations' },
        ],
      },
    ],
  },
]
