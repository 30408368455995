/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetNewEventDto } from '../models/GetNewEventDto';
import type { PromoCodeDto } from '../models/PromoCodeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventService {

  /**
   * @returns GetNewEventDto Success
   * @throws ApiError
   */
  public static getEventGetEvents(): CancelablePromise<Array<GetNewEventDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/event/get-events',
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }

  /**
   * @returns number Success
   * @throws ApiError
   */
  public static getEventGetActivePromoCodeEvent(): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/Event/GetActivePromoCodeEvent',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @param leadId 
   * @param eventId 
   * @returns PromoCodeDto Success
   * @throws ApiError
   */
  public static getEventGetPromoCode(
leadId?: number,
eventId?: number,
): CancelablePromise<PromoCodeDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/Event/GetPromoCode',
      query: {
        'leadId': leadId,
        'eventId': eventId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

}
