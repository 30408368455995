/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetNewEventDto } from '../models/GetNewEventDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SalesOfferSetupService {

  /**
   * @param urlCode 
   * @returns GetNewEventDto Success
   * @throws ApiError
   */
  public static getApiSalesOfferSetupEventOffers(
urlCode: string,
): CancelablePromise<GetNewEventDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/sales-offer-setup/event-offers/{urlCode}',
      path: {
        'urlCode': urlCode,
      },
      errors: {
        400: `Bad Request`,
        500: `Server Error`,
      },
    });
  }

}
