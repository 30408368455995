/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Value of enum is id of transmission type
 *
 * 0 = Undefined
 *
 * 1 = ManualOld (Code "M")
 *
 * 2 = Speed4
 *
 * 3 = Speed5
 *
 * 4 = Speed6
 *
 * 5 = Automatic
 *
 * 6 = Cvt (CVT Continuously Variable Transmission)
 *
 * 7 = SemiAtomatic
 *
 * 8 = Manual (Code "MANUAL")
 */
export enum TransmissionType {
  Undefined = 0,
  /**
   * Code "M"
   */
  ManualOld = 1,
  Speed4 = 2,
  Speed5 = 3,
  Speed6 = 4,
  Automatic = 5,
  /**
   * CVT Continuously Variable Transmission
   */
  Cvt = 6,
  SemiAtomatic = 7,
  /**
   * Code "MANUAL"
   */
  Manual = 8,
}
