import { Fragment } from 'react'

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/16/solid'
import { Link } from '../../../components/common/Link'

import { SideBarMenuItem } from './SidebarMenuItem'
import { SideBarMenuSection } from './SidebarMenuSection'
import { menuSections } from '../menuConfig'

export const SideBarMenu = () => {
  return (
    <div className='flex justify-end'>
      <Popover>
        {({ close, open }) => (
          <>
            <PopoverButton className='inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none bg-white'>
              <Bars3Icon className='text-regal-red h-8 w-8' />
            </PopoverButton>
            <PopoverPanel unmount={false} transition>
              <div
                className='fixed inset-0 overflow-hidden lg:hidden'
                id='headlessui-dialog-:r1q:'
                role='dialog'
                aria-modal='true'
                data-headlessui-state='open'
              >
                <div
                  className='absolute inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity z-40'
                  onClick={close}
                />
                <Transition
                  as={Fragment}
                  enter='transition-transform ease-out duration-200'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform ease-in duration-200'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                  transition
                  show={open}
                >
                  <div className='popover-panel'>
                    <div className='bg-white shadow-2xl ring-1 ring-black/10 transition h-full w-full'>
                      <button
                        type='button'
                        className='absolute right-4 top-5 flex items-center justify-center'
                        onClick={close}
                      >
                        <XMarkIcon className='text-regal-red bg-white h-10 w-10' />
                      </button>
                      <nav className='divide-y divide-slate-900/10 text-base leading-7 text-slate-900 flex flex-col h-full'>
                        <div className='px-8 py-4 flex-shrink-0'>
                          <a className='flex-shrink-0 block' href='/' style={{ height: 45 }}>
                            <img
                              src='/img/logo.svg'
                              alt='Advance Motors'
                              aria-label='Advance Motors'
                              style={{ width: 186, height: 45 }}
                            />
                          </a>
                        </div>
                        <div className='scrollbar flex-grow overflow-y-auto'>
                          <div className='px-8 py-6'>
                            <div className='-my-2 items-start space-y-2'>
                              {menuSections.map((item) =>
                                item.isLink ? (
                                  <Link
                                    key={item.href}
                                    className='text-gray-500 block w-full py-2 font-semibold text-base'
                                    to={item.href ?? ''}
                                    onClick={close}
                                    isExternal={item.isNextLink}
                                  >
                                    {item.text}
                                  </Link>
                                ) : (
                                  <SideBarMenuItem key={item.title} title={item.title} onClose={close}>
                                    {item.sections.map(({ title, ...rest }) => (
                                      <SideBarMenuSection key={title} title={title} onItemClick={close} {...rest} />
                                    ))}
                                  </SideBarMenuItem>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </Transition>
              </div>
            </PopoverPanel>
          </>
        )}
      </Popover>
    </div>
  )
}
