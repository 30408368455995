import { useState, useEffect, useRef, memo } from 'react'
import { Branch } from '../../../models/branch/Branch'
import 'react-tabs/style/react-tabs.css'
import Modal from './Modal'
import './ContactUsRight.scss'
import Accordion from '../Accordion'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { useAppDispatch } from '../../common/hooks/reduxHooks'
import { contactUsActions } from '../../../utils/slices/contactUsChatSlice'

const ContactUsRight = ({ closeContactUs }: any) => {
  const [branch, setBranch] = useState<Branch>()
  const [showModal, setShowModal] = useState(false)
  const ref = useRef<any>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleShowModal = (e: Branch) => {
    setBranch(e)
    setShowModal(true)
    //closeContactUs()
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useOutsideClick(ref, () => {
    closeContactUs()
  })

  const handleChatClick = () => {
    closeContactUs()
    dispatch(contactUsActions.setChatExpanded(true))
  }

  return (
    <div ref={ref} className='main-menu'>
      {branch && <Modal branch={branch} showModal={showModal} onClose={handleCloseModal} />}

      <div id='mainMenuContainer' className={'main-menu__container js-active'}>
        <div
          className='main-menu__close main-menu__close--top'
          onClick={() => {
            closeContactUs()
          }}
        ></div>
        <div className='main-menu__title'>Advance Motors</div>

        <Accordion handleShowModal={handleShowModal} onChatClick={handleChatClick} />
      </div>
    </div>
  )
}

export default memo(ContactUsRight)
