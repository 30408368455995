/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Value of enum is id of fuel type, description is code
 *
 * 0 = Undefined
 *
 * 1 = Star2 (2-Star)
 *
 * 2 = BiFuelGas (Bi-fuel Gas)
 *
 * 3 = BiFuelPetrol (Bi-fuel Petrol)
 *
 * 4 = Diesel
 *
 * 5 = ElectricOnly (Electric only)
 *
 * 6 = Hybrid
 *
 * 7 = Lpg
 *
 * 8 = Mixed
 *
 * 9 = Other
 *
 * 10 = Petrol
 *
 * 11 = Unleaded
 */
export enum FuelType {
  Undefined = 0,
  /**
   * 2-Star
   */
  Star2 = 1,
  /**
   * Bi-fuel Gas
   */
  BiFuelGas = 2,
  /**
   * Bi-fuel Petrol
   */
  BiFuelPetrol = 3,
  Diesel = 4,
  /**
   * Electric only
   */
  ElectricOnly = 5,
  Hybrid = 6,
  Lpg = 7,
  Mixed = 8,
  Other = 9,
  Petrol = 10,
  Unleaded = 11,
}
