/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = Slough
 *
 * 2 = HighWycombe
 *
 * 3 = Maidenhead
 *
 * 4 = Watford
 *
 * 6 = HemelHempstead
 *
 * 7 = Romford
 */
export enum BranchType {
  Slough = 1,
  HighWycombe = 2,
  Maidenhead = 3,
  Watford = 4,
  HemelHempstead = 6,
  Romford = 7,
}
