import { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../index'

import { errorToast } from '../../utils/toasts'
import { chatApi } from '../../api/chat'
import { getChatSessionData } from '../chat'
import { contactUsActions } from '../slices/contactUsChatSlice'
import moment from 'moment'
import { InitConversation } from '../../models/contactUs/InitConversation'

export const fetchInitConversation = (data: InitConversation): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    const sessionData = getChatSessionData()

    chatApi
      .fetchInitConversation(sessionData.userId, data)
      .then((response) => {
        dispatch(contactUsActions.setUserId(sessionData.userId))
        if (data.conversationType === 0) {
          dispatch(contactUsActions.setConversationId(response))
          dispatch(contactUsActions.setStartedDate(moment().format('DD/MM/YYYY')))
          dispatch(contactUsActions.setStartedTime(moment().format('hh:mm:ss')))
        } else {
          dispatch(contactUsActions.setVaConversationId(response))
          dispatch(contactUsActions.setVaStartedDate(moment().format('DD/MM/YYYY')))
          dispatch(contactUsActions.setVaStartedTime(moment().format('hh:mm:ss')))
        }
      })
      .catch((e) => {
        errorToast(e)
      })
  }
}

export const fetchMessages = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const conversationId = getState().contactUs.conversationId
    const userId = getState().contactUs.userId
    if (conversationId && userId)
      chatApi
        .fetchTwilioMessages(conversationId, userId)
        .then((response) => {
          dispatch(contactUsActions.setMessages(response))
        })
        .catch((e) => {
          errorToast(e)
        })
  }
}

export const fetchVaMessages = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const conversationId = getState().contactUs.vaConversationId
    const userId = getState().contactUs.userId
    if (conversationId && userId)
      chatApi
        .fetchTwilioMessages(conversationId, userId)
        .then((response) => {
          dispatch(contactUsActions.setVaMessages(response))
        })
        .catch((e) => {
          errorToast(e)
        })
  }
}
