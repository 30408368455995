import { createSlice, createSelector } from '@reduxjs/toolkit';

const slice = createSlice({
	name: 'app',
	initialState: {
		loading: false,
		thankYour:null
	//	carModels: {}
	},
	reducers: {

		

		setLoading: (state, { payload: { loading } }) => {
			state.loading = loading;
		},
		/*
		setThankYour: (state, { payload: { thankYour } }) => {

			
			state.thankYour =thankYour;
			console.log("thankYour",thankYour);

		},*/

	/*	setCarModels: (state, { payload: { data } }) => {

			let carModels = state.carModels;
			state.carModels = { ...carModels, ...data };

		},
*/


	},
});


export const actions = slice.actions;
export const reducer = slice.reducer;
