/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Mr
 *
 * 1 = Miss
 *
 * 2 = Mrs
 *
 * 3 = Ms
 *
 * 4 = Dr
 *
 * 5 = Prof
 *
 * 6 = Rev
 */
export enum Titles {
  Mr = 0,
  Miss = 1,
  Mrs = 2,
  Ms = 3,
  Dr = 4,
  Prof = 5,
  Rev = 6,
}
