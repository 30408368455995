/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = All
 *
 * 1 = Max13 (1L-1.3L)
 *
 * 2 = Min14Max16 (1.4L-1.6L)
 *
 * 3 = Min17Max19 (1.7L-1.9L)
 *
 * 4 = Min2Max25 (2L-2.5L)
 *
 * 5 = MoreThan25 (more than 2.5L)
 */
export enum EngineSizeFilter {
  All = 0,
  /**
   * 1L-1.3L
   */
  Max13 = 1,
  /**
   * 1.4L-1.6L
   */
  Min14Max16 = 2,
  /**
   * 1.7L-1.9L
   */
  Min17Max19 = 3,
  /**
   * 2L-2.5L
   */
  Min2Max25 = 4,
  /**
   * more than 2.5L
   */
  MoreThan25 = 5,
}
