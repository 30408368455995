/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArticleDTO } from '../models/ArticleDTO';
import type { ArticleListDTOPagedListData } from '../models/ArticleListDTOPagedListData';
import type { ArticlePublishStatus } from '../models/ArticlePublishStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NewsAndArticlesService {

  /**
   * Retrieves all articles with pagination and optional filters.
   * @param articleName Gets or sets the name of the article to filter by.
 * If provided, only articles containing the specified name will be retrieved.
   * @param publishStatus Gets or sets the publish status of the articles to filter by.
 * If provided, only articles matching the specified publish status will be retrieved.
 *
 * 0 = Draft
 *
 * 1 = Published
   * @param isFeatured Gets or sets a value indicating whether to filter by featured articles.
 * If true, only featured articles will be retrieved. If false, only non-featured articles will be retrieved.
 * If null, the filter will ignore the featured status.
   * @param skipPagination Пропустить пагинацию
   * @param page Страница
   * @param maxCount Максимально количество записей на странице
   * @returns ArticleListDTOPagedListData Returns a list of articles.
   * @throws ApiError
   */
  public static getNewsAndArticlesGetAllArticles(
articleName?: string,
publishStatus?: ArticlePublishStatus,
isFeatured?: boolean,
skipPagination?: boolean,
page?: number,
maxCount?: number,
): CancelablePromise<ArticleListDTOPagedListData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/news-and-articles/get-all-articles',
      query: {
        'ArticleName': articleName,
        'PublishStatus': publishStatus,
        'isFeatured': isFeatured,
        'SkipPagination': skipPagination,
        'Page': page,
        'MaxCount': maxCount,
      },
      errors: {
        500: `Internal server error occurred.`,
      },
    });
  }

  /**
   * Retrieves a specific article by its ID.
   * @param id The ID of the article to retrieve.
   * @param userId The Id of current user
   * @returns ArticleDTO Returns the article if found.
   * @throws ApiError
   */
  public static getNewsAndArticlesGetArticle(
id: number,
userId?: number,
): CancelablePromise<ArticleDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/news-and-articles/get-article/{id}',
      path: {
        'id': id,
      },
      query: {
        'userId': userId,
      },
      errors: {
        404: `Article not found.`,
        500: `Internal server error occurred.`,
      },
    });
  }

}
