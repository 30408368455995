import { actions, store } from './store'
import { useSelector } from 'react-redux'
import * as apiExport from './api'
import * as functions from './functions'
import { ApiError } from '../api/generated'

let reduxExport: any & { selector: any } = { ...actions, selector: useSelector }

export const redux = reduxExport
export const api = apiExport
export const helpers = functions
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

////////////////////////////
export const carModels: any = { '1': [], '2': [] }
export let CurrentContext: any = {}

export const dataLayerPush = (param: any) => {
  const win: any = window

  let dLayer: any = win.dataLayer
  // добавляем если нет
  if (!dLayer) {
    win.dataLayer = []
    dLayer = win.dataLayer
  }
  dLayer.push(param)
  //console.log("win.dataLayer",win.dataLayer);
}

export const leadsUserData = (email: string | undefined) => {
  try {
    if (!email) return

    if (process.env.REACT_APP_MODE == 'production') {
      const win: any = window
      let dLayer: any = win.dataLayer
      if (!dLayer) {
        win.dataLayer = []
        dLayer = win.dataLayer
      }

      dLayer.push({
        leadsUserData: {
          email: email,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const gtagSendForm = (formName: string) => {
  try {
    let url = window.location.origin + window.location.pathname
    url = url.replace(/\/+$/, '')
    const win: any = window
    win.gtag('event', 'send_form', {
      form_name: formName,
      page_url: url,
    })
  } catch (error) {
    console.log(error)
  }
}

export const setCurrentContext = (context: any) => {
  CurrentContext = context
}

export const setDataByPath = (data: any, path: string, value: any) => {
  let arr = path.split('/')
  let curData = data
  arr.forEach((name: string, index: number) => {
    if (index == arr.length - 1) curData[name] = value
    else {
      if (!curData[name]) curData[name] = {}
      curData = curData[name]
    }
  })

  //  console.log("data",data);

  return data
}

export const getDataByPath = (data: any, path: string) => {
  let arr = path.split('/')
  let curData = data
  let value: any = ''
  arr.forEach((name: string, index: number) => {
    if (index == arr.length - 1) value = curData[name]
    else {
      if (!curData[name]) curData[name] = {}
      curData = curData[name]
    }
  })

  return value === undefined ? '' : value
}

export const searchToJson = (request: string) => {
  let data: any = {}

  request
    .replace('?', '')
    .split('&')
    .forEach((str) => {
      let arr = str.split('=')
      data[arr[0]] = arr[1]
    })

  return data
}

export const jsonToSearch = (data: any) => {
  const getValue = (value: any) => {
    if (Array.isArray(value)) return `[${value.join(',')}]`

    return value
  }

  return data
    ? '?' +
        Object.keys(data)
          .filter((key) => data[key])
          .map((key) => key + '=' + getValue(data[key]))
          .join('&')
    : ''
}

export const isObjectEmpty = (objectName: any) => {
  return Object.keys(objectName).length === 0
}

export function formatErrorMessage(error: ApiError) {
  const message = error?.body?.error?.message ?? 'Something went wrong'
  return message
}
