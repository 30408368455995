import { FC } from 'react'
import { TwilioMessage } from '../../../../models/contactUs/TwilioMessage'
import moment from 'moment'

interface IProps {
  message: TwilioMessage
}

const OutBoundMessage: FC<IProps> = ({ message }) => {
  return (
    <div className='chat__message'>
      <div className='chat__message-bubble'>{message.message}</div>
      <div className='chat__message-info'>
        <span className='chat__author'>{message.sender}</span>
        <time className='chat__date'>{moment(message.dateSent).format('hh:mm')}</time>
      </div>
    </div>
  )
}

export default OutBoundMessage
