import { FC } from 'react'
import classNames from 'classnames'
import styles from './SvgButton.module.css'

interface ButtonProps {
  text?: string
  disabled?: boolean
  isMaxWidth?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SvgButton: FC<React.PropsWithChildren<ButtonProps>> = ({ text, disabled, isMaxWidth, onClick, children }) => {
  return (
    <button
      className={classNames(isMaxWidth ? styles.buttonChat : '', styles.svgButton)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <span className={styles.buttonSpan}>{text}</span>
    </button>
  )
}

export default SvgButton
