import "./LoaderSpinner.css"
import { usePromiseTracker } from "react-promise-tracker";

const LoaderSpinner = () => {

    const { promiseInProgress } = usePromiseTracker({ delay: 800 });

    return (
        <>
            {promiseInProgress && <div className="preloader" style={{ display: "block" }}>
                <div className="preloader-load" style={{ display: "block" }}>
                    <span></span>
                </div>
            </div>}
        </>);
}

export default LoaderSpinner;