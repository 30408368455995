import { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../common/hooks/reduxHooks'
import { contactUsActions } from '../../../../utils/slices/contactUsChatSlice'
import Scrollbars from 'react-custom-scrollbars-2'
import { fetchInitConversation, fetchVaMessages } from '../../../../utils/actions/contactUsChatActions'
import InBoundMessage from './InBoundMessage'
import OutBoundMessage from './OutBoundMessage'
import { chatApi } from '../../../../api/chat'
import moment from 'moment'
import { TwilioMessage } from '../../../../models/contactUs/TwilioMessage'
import Form from './Form'
import * as utils from '../../../../utils'
import { getStockCarId } from '../../../../utils/functions'
import { InitConversation } from '../../../../models/contactUs/InitConversation'
import { TypeAnimation } from 'react-type-animation'

const Chat: FC = () => {
  const { vaConversationId, userId, isVaChatExpanded, vaStartedDate, vaStartedTime, vaConversationClosed } =
    useAppSelector((state) => state.contactUs)

  const messagesList = useAppSelector((state) => state.contactUs.vaMessages)
  const showTyping = useAppSelector((state) => state.contactUs.showTyping)
  const { connectionClosed } = useAppSelector((state) => state.environment)
  const [message, setMessage] = useState<string>('')

  const dispatch = useAppDispatch()
  const chatRef = useRef<any>()

  const addMessage = (newMessage: TwilioMessage) => {
    dispatch(contactUsActions.addVaMessage(newMessage))
  }

  useEffect(() => {
    if (!vaConversationId && isVaChatExpanded) {
      let stockId = ''
      if (window.location.pathname.includes('/stock-car/')) {
        const id = window.location.pathname.split('/').pop()
        stockId = getStockCarId(id)
        const model: InitConversation = {
          conversationType: 2,
          stockId: stockId ?? undefined,
        }
        dispatch(fetchInitConversation(model))
      } else if (window.location.pathname.includes('/catalog/')) {
        let modelId = ''
        const id = window.location.pathname.split('/').pop()
        modelId = getStockCarId(id)
        utils.api.fetchData(`GetDealConfiguration?variantId=${modelId}`).then((resp: any) => {
          const model: InitConversation = {
            conversationType: 3,
            modelId: modelId,
            modelYear: resp?.variant?.modelYear,
            carModelName: resp?.variant?.originModel,
          }
          dispatch(fetchInitConversation(model))
        })
      }
    }
  }, [vaConversationId, isVaChatExpanded])

  useEffect(() => {
    if (messagesList && messagesList.length && isVaChatExpanded) {
      chatRef?.current?.scrollToBottom()
    }
  }, [messagesList])

  useEffect(() => {
    if (vaConversationId) {
      dispatch(fetchVaMessages())
    }
  }, [vaConversationId])

  // useEffect(() => {
  //   if (showTyping) {
  //     setTimeout(() => {
  //       setShowTyping(false)
  //     }, 3000)
  //   }
  // }, [showTyping])

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setMessage(e.target.value)
  }

  const handleSendMessage = () => {
    if (vaConversationId && message && userId) {
      setMessage('')
      let stockId = ''
      if (window.location.pathname.includes('/stock-car/')) {
        const id = window.location.pathname.split('/').pop()
        stockId = getStockCarId(id)
      }

      const formData = new FormData()
      formData.append('ConversationSid', vaConversationId)
      formData.append('Message', message)
      formData.append('CustomerUid', userId)
      if (stockId) formData.append('StockId', stockId)
      formData.append('ClientCurrentUrl', window.location.href)
      chatApi.fetchSendMessage(formData).then(() => {
        const newMessage: TwilioMessage = {
          message: message,
          sender: 'Visitor',
          dateSent: moment().format('YYYY-MM-DDTHH:mm:ss'),
          type: 1,
          typeName: 'In',
          status: undefined,
          statusName: undefined,
          photoUrl: undefined,
          medias: undefined,
        }
        addMessage(newMessage)
      })
    }
  }

  return (
    <div className='contact-us' style={{ display: 'flex' }}>
      <div className='chat contact-us__chat' style={isVaChatExpanded ? { display: 'block' } : { display: 'none' }}>
        <div className='chat__top'>
          <div className='chat__title'>
            <svg width='38' height='25' viewBox='0 0 38 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M15.6411 0L22.222 10.1024C25.2675 10.4196 28.2757 11.026 31.2058 11.9134C25.4435 11.2842 21.5649 11.3423 18.0864 11.7336L15.6411 7.62502L12.5387 12.8335C9.69073 13.6029 7.01493 14.79 4.83984 16.5667L15.6411 0Z'
                fill='#595958'
              />
              <path
                d='M37.2175 14.6895C33.212 14.4577 29.1943 14.5461 25.2029 14.9538L31.2963 24.0119H25.9685L20.4686 15.6809C8.28174 18.1583 5.33306 23.9378 5.33306 23.9378L0 24.0119C10.1257 7.27332 37.2175 14.6974 37.2175 14.6974'
                fill='#AC1919'
              />
            </svg>
            <span>Advance</span>
          </div>
          <div
            className='chat__close'
            onClick={(e) => {
              e.preventDefault()
              dispatch(contactUsActions.setVaChatExpanded(false))
            }}
          >
            <svg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
              <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' />
            </svg>
          </div>
        </div>

        <div className='chat__main'>
          <div className='chat__scroll'>
            <Scrollbars
              ref={chatRef}
              //autoHeight
              //autoHide
              //autoHeightMin='390px'
              //autoHeightMax='390px'
              // style={{ width: '100%' }}
              renderTrackHorizontal={(props) => (
                <div {...props} className='track-horizontal' style={{ display: 'none' }} />
              )}
              renderView={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    paddingRight: '15px',
                    paddingBottom: '15px',
                    overflowX: 'hidden',
                  }}
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    width: '3px',
                    borderRadius: '4px',
                    backgroundColor: '#d4141c',
                  }}
                />
              )}
            >
              {connectionClosed || vaConversationClosed ? (
                <div className='chat__started'>
                  <span>Connection closed. Try refreshing this page to restart the connection.</span>
                </div>
              ) : (
                <div className='chat__started'>
                  <div className='chat__started-title'>{vaConversationId ? 'Chat started' : 'Loading...'}</div>
                  <div className='chat__started-date'>
                    {vaStartedDate && <time>{vaStartedDate}</time>}, {vaStartedTime && <time>{vaStartedTime}</time>}
                  </div>
                </div>
              )}

              {!connectionClosed &&
                !vaConversationClosed &&
                messagesList?.map((group) => (
                  <>
                    <div className='chat__message__label'>
                      <div className='chat__message__label__line'></div>
                      <div className='chat__message__label__text'>{moment(group.date).format('DD MMM yyyy')}</div>
                      <div className='chat__message__label__line'></div>
                    </div>
                    {group.messages.map((message) => {
                      if (message.type === 2) return <InBoundMessage key={message.id} message={message} />
                      else return <OutBoundMessage key={message.id} message={message} />
                    })}
                  </>
                ))}
            </Scrollbars>
          </div>
        </div>

        {showTyping && (
          <div style={{ margin: '10px 18px', fontSize: '12px', color: '#9da4b0', display: 'flex' }}>
            <span>Ava is typing</span>
            <TypeAnimation sequence={['...', 1000, '']} wrapper='div' cursor={true} repeat={Infinity} />
          </div>
        )}

        <Form message={message} handleMessageChange={handleMessageChange} handleSendMessage={handleSendMessage} />
      </div>
    </div>
  )
}

export default Chat
