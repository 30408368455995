import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import filter from 'lodash/filter'

export const newCarDetailsApi = createApi({
  reducerPath: 'newCarDetailsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  }),
  endpoints: (builder) => ({
    getNewCarDetails: builder.query<any, string>({
      query: (arg) => ({ url: `GetDealConfiguration${arg}`, method: 'GET' }),
      transformResponse: (response: any) => {
        if (response === null) {
          return { notFound: true }
        }
        if (response.variant.id === 27443) {
          if (response.colors && response.colors.length > 0) {
            let newColors = filter(response.colors, { id: 143 })
            if (newColors && newColors.length > 0) {
              newColors[0].price.price = 0
              newColors[0].isDefault = true
              response.colors = newColors
            }
          }
        }
        return response
      },
    }),
  }),
})

export const { useGetNewCarDetailsQuery } = newCarDetailsApi
