import { useClose } from '@headlessui/react'
import SVG from 'react-inlinesvg'
import { AppLink, SectionItem } from '../menuConfig'
import { Link } from '../../../components/common/Link'

interface MenuItemSectionProps extends AppLink {
  title: string
  titleLink?: string
  items: SectionItem[]
  icon?: { className: string; path: string }
  order?: number
}

export const MenuItemSection = ({ title, titleLink, items, isNextLink, icon, order }: MenuItemSectionProps) => {
  const close = useClose()
  return (
    <div className='flex flex-col gap-4 w-72' style={{ order }}>
      <div className='border-b-2 border-red-500 py-1'>
        {titleLink ? (
          <Link
            to={titleLink}
            onClick={close}
            isExternal={isNextLink}
            className='text-gray-500 tracking-wide uppercase text-sm font-medium flex items-center gap-2'
          >
            {icon && <SVG src={icon.path} className={icon.className} />}
            {title}
          </Link>
        ) : (
          <span className='text-gray-500 tracking-wide uppercase text-sm font-medium flex items-center gap-2'>
            {icon && <SVG src={icon.path} className={icon.className} />}
            {title}
          </span>
        )}
      </div>
      {items.map(({ title, link, isNextLink, icon }) => (
        <Link
          key={title}
          to={link ?? '#'}
          className='text-medium font-medium text-gray-900 flex items-center gap-2'
          onClick={close}
          isExternal={isNextLink}
        >
          {icon && <SVG src={icon.path} className={icon.className} />}
          {title}
        </Link>
      ))}
    </div>
  )
}
