import React, { useState } from 'react'
import { UserEnvironment } from '../../models/userEnvironment/UserEnvironment'
//import { getBanner } from "../../utils/banner";
//import { HubConnection } from '@microsoft/signalr'
// import { useImportScript, useImportCss } from '../../utils/useImportScript'
// import useImportScriptDeffer from '../hooks/useImportScriptDeffer'
import { Branch } from '../../models/branch/Branch'
import { branchesApi } from '../../api/branches'
//import { eventApi } from '../../api/events'
import { useAppDispatch } from '../common/hooks/reduxHooks'
import { startConnecting } from '../../utils/slices/envSlice'
import { useFetchEventGetEventsQuery } from '../common/api/repositories/use-get-events.query'
//import { useAppDispatch, useAppSelector } from '../common/hooks/reduxHooks'

export const EnvironmentContext = React.createContext<{ env: UserEnvironment }>({ env: {} })

const EnvironmentProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const [env, setEnv] = React.useState<UserEnvironment>({ appSettings: { showBanner: true } })
  //const [hubConnection, setHubConnection] = React.useState<HubConnection>()
  const [branches, setBranches] = useState<Branch[] | undefined>()
  //const [events, setEvents] = useState<CommonEvent[] | undefined>()
  const dispatch = useAppDispatch()

  const { data: events } = useFetchEventGetEventsQuery()

  //useImportScript("/js/main.js");
  //
  /*    useImportScript("/js/libs/jquery-3.3.1.min.js");
        useImportScript("/js/libs/jquery.fancybox.min.js");
        useImportScript("/js/libs/owl.carousel.js");
        useImportScript("/js/libs/jquery.mCustomScrollbar.concat.min.js");
        useImportScript("/js/libs/tooltipster.bundle.min.js");*/
  // if (process.env.REACT_APP_MODE == 'production') {
  //     useImportScriptDeffer("/js/partials/twillio.js", env?.branches);
  // }

  // React.useEffect(() => {
  //   if (!env) {
  //     // const banner = getBanner();
  //     // setEnv({ appSettings: banner && banner.isHidden ? { showBanner: false } : { showBanner: true } });

  //     setEnv({ appSettings: { showBanner: true } })
  //   }
  // }, [env])

  // React.useEffect(() => {
  //   //let conversationSid = ''
  //   if (!twilioClient) {
  //     sessionDataHandler
  //       .fetchAndStoreNewSession({
  //         formData: {
  //           friendlyName: 'Visitor',
  //           email: '',
  //           query: '',
  //         },
  //       })
  //       .then((data) => {
  //         dispatch(
  //           initSession({
  //             token: data.twilioToken,
  //             isWorkingHours: data.ifBranchWorking,
  //             welcomeMessage: data.welcomeMessage,
  //           }),
  //         )

  //         if (data.conversationSid != '') {
  //           setConversationSid(data.conversationSid)
  //           // dispatch(
  //           //   initConversationSession({
  //           //     conversationSid: data.conversationSid,
  //           //   }),
  //           // )
  //         }
  //       })
  //   } else if (conversationSid) {
  //     twilioClient.on('initialized', () => {
  //       console.log('initialized')
  //       dispatch(
  //         initConversationSession({
  //           conversationSid: conversationSid,
  //         }),
  //       )
  //     })
  //   }
  // }, [twilioClient, conversationSid])

  // const onTwilioLoaded = () => {
  //   const win: any = window
  //   win.Twilio.initWebchat({
  //     serverUrl: '%REACT_APP_SERVER_URL%',
  //     theme: {
  //       isLight: true,
  //     },
  //   })
  // }

  //useImportScriptDeffer("/js/partials/twilio-conversation_v2.js", env?.branches, onTwilioLoaded);

  React.useEffect(() => {
    if (events?.length && env?.branches?.length && !env?.events?.length) {
      setEnv({ ...env, events: events })
    }
  }, [events, branches, env])

  React.useEffect(() => {
    if (branches) {
      setEnv({ ...env, branches: branches })
    }
  }, [branches])

  React.useEffect(() => {
    dispatch(startConnecting())
  }, [])

  React.useEffect(() => {
    if (!branches) {
      branchesApi.fetchGetBranches().then((response) => {
        if (response) {
          setBranches(response)
        } else {
          setBranches([])
        }
      })
    }
  }, [])

  // React.useEffect(() => {
  //   if (env) {
  //     if (!hubConnection) {
  //       // setHubConnection(() => {
  //       //     env.hubConnection = new HubConnectionBuilder()
  //       //         .withUrl("https://internalapitest.advance-motors.co.uk/EventBunnerHub/")
  //       //         //.configureLogging(LogLevel.Trace)
  //       //         .withAutomaticReconnect()
  //       //         .build();
  //       //     return env.hubConnection;
  //       // });
  //     } else {
  //       // hubConnection.start()
  //       //     .then(() => {
  //       //         console.log("Hub started");
  //       //         hubConnection.on('SendBannerState', message => {
  //       //         });
  //       //         // hubConnection.invoke("joinGroup").then(() => {
  //       //         //     console.log("User joined the groups");
  //       //         // });
  //       //         //SendBannerState
  //       //     })
  //       //     .catch((err: string) => {
  //       //         console.log("Hub error while establishing connection", err);
  //       //     });
  //     }
  //   }
  //   return (): void => {
  //     if (env && hubConnection) {
  //       // hubConnection.invoke("leaveGroup", {
  //       // }).then(() => {
  //       //     console.log("User left the groups");
  //       // });//SendBannerState
  //     }
  //   }
  // }, [env, hubConnection])

  return env ? <EnvironmentContext.Provider value={{ env }}>{children}</EnvironmentContext.Provider> : null
}

export default EnvironmentProvider

// React.useEffect(() => {
//     if (env.hubConnection) {
//         env.hubConnection.off("communicationMessageStatus");
//         env.hubConnection.on("communicationMessageStatus", handleTwillioMessageStatus);
//     }
//     return (): void => {
//         if (env.hubConnection) {
//             env.hubConnection.off("communicationMessageStatus");
//         }
//     }
// }, [env.hubConnection, handleTwillioMessageStatus]);
