/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FuelType } from '../models/FuelType'
import type { LookupFixedPriceDto } from '../models/LookupFixedPriceDto'
import type { VarClassEnum } from '../models/VarClassEnum'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ServicePriceService {
  /**
   * @param modelName
   * @param fuelType
   *
   * 0 = Undefined
   *
   * 1 = Star2 (2-Star)
   *
   * 2 = BiFuelGas (Bi-fuel Gas)
   *
   * 3 = BiFuelPetrol (Bi-fuel Petrol)
   *
   * 4 = Diesel
   *
   * 5 = ElectricOnly (Electric only)
   *
   * 6 = Hybrid
   *
   * 7 = Lpg
   *
   * 8 = Mixed
   *
   * 9 = Other
   *
   * 10 = Petrol
   *
   * 11 = Unleaded
   * @param registrationDate
   * @param carType
   *
   * 0 = NotChosen
   *
   * 1 = Car
   *
   * 2 = Lcv
   * @param manufacturer
   * @returns LookupFixedPriceDto Success
   * @throws ApiError
   */
  public static getServicePriceLookUpFixedPrices(
    modelName?: string,
    fuelType?: FuelType,
    registrationDate?: string,
    carType?: VarClassEnum,
    manufacturer?: number,
  ): CancelablePromise<Array<LookupFixedPriceDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service-price/look-up-fixed-prices',
      query: {
        ModelName: modelName,
        FuelType: fuelType,
        RegistrationDate: registrationDate,
        CarType: carType,
        Manufacturer: manufacturer,
      },
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    })
  }
}
