import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { Middleware } from '@reduxjs/toolkit'
import envSlice, { setHubConnection } from '../slices/envSlice'
import { getChatSessionData } from '../chat'

const BASE_URL = process.env.REACT_APP_API_CONNECT_URL
const sessionData = getChatSessionData()
let hubConnection = new HubConnectionBuilder()
  .withUrl(`${BASE_URL}/NotificationHub?customerUid=${sessionData.userId}`, {
    transport: HttpTransportType.WebSockets,
  })
  .configureLogging(LogLevel.Information)
  .withAutomaticReconnect()
  .build()

const signalRMiddleware: Middleware = (store) => (next) => (action) => {
  if (!envSlice.actions.startConnecting.match(action)) {
    return next(action)
  }
  hubConnection
    .start()
    .then(() => {
      console.log('Connect Hub started')
      store.dispatch(setHubConnection(hubConnection))
    })
    .catch((err: string) => {
      console.log('Hub error while establishing connection', err)
    })

  next(action)
}

export default signalRMiddleware
