import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { PopoverPanel, useClose } from '@headlessui/react'
import { ArrowLeftIcon } from '@heroicons/react/16/solid'

interface IProps {
  children: Readonly<React.ReactNode>
  onClose?: () => void
}

const SubPopoverPanel: FunctionComponent<IProps> = ({ children, onClose }) => {
  const close = useClose()
  return (
    <PopoverPanel transition>
      <div
        className='fixed inset-0 overflow-hidden lg:hidden'
        id='headlessui-dialog-:r1q:'
        role='dialog'
        aria-modal='true'
        data-headlessui-state='open'
      >
        <div
          onClick={onClose}
          className='absolute inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity z-40'
        ></div>
        <div className='popover-panel'>
          <div className='bg-white shadow-2xl ring-1 ring-black/10 transition h-full w-full'>
            <button
              type='button'
              className='absolute right-5 top-6 flex items-center justify-center bg-white'
              onClick={close}
            >
              <ArrowLeftIcon className='text-regal-red h-8 w-8' />
            </button>
            <nav className='divide-y divide-slate-900/10 text-base leading-7 text-slate-900 flex flex-col h-full'>
              <div className='px-8 py-4 flex-shrink-0'>
                <a className='header__logo flex-shrink-0 block' href='/' style={{ height: 45 }}>
                  <img
                    src='/img/logo.svg'
                    alt='Advance Motors'
                    aria-label='Advance Motors'
                    style={{ width: 186, height: 45 }}
                  />
                </a>
              </div>
              <div className='flex-grow overflow-x-auto scrollbar'>{children}</div>
            </nav>
          </div>
        </div>
      </div>
    </PopoverPanel>
  )
}

export default SubPopoverPanel
