import { HubConnection } from '@microsoft/signalr'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnvironmentReducerModel } from '../../models/environment/EnvironmentReducerModel'

const initialEnvState: EnvironmentReducerModel = {
  isLoading: false,
  hubConnection: undefined,
  connectionClosed: true,
}

const envSlice = createSlice({
  name: 'env',
  initialState: initialEnvState,
  reducers: {
    setLoading(state, { payload: { loading } }) {
      state.isLoading = loading
    },
    setConnectionClosed(state, action: PayloadAction<boolean>) {
      state.connectionClosed = action.payload
    },
    startConnecting: () => {},
    setHubConnection(state, action: PayloadAction<HubConnection>) {
      state.hubConnection = action.payload
    },
  },
})

export default envSlice
export const { setHubConnection, startConnecting, setConnectionClosed } = envSlice.actions
